import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/layout'


import p1 from '../../images/products/Datenmigrationen/Overview.png'

const abacusHR = () => (
    <Layout>
        <section id="datenmigrationen" className="section-wrapper">
            <header className="section-header">
                <h3>Datenmigrationen</h3>
            </header>
            <div className="post" id="overview">
                <p>
                    Datenübernahmen aus den bisherigen HR Systemen nach Abacus gehören zu den Kernkompetenzen von L+P. Automatische Migrationen machen ein HR System Ablösungsprojekt sehr effizient und die Benützer werden entlastet. Folgende Datenbereiche können migriert werden
                </p>
                <img src={p1} alt="Abacus Prozesse" width="100%" />
                <br />
                <br />
                <br />
                <table>
                    <tr>
                        <th>Datenbereich</th>
                        <th>Bemerkungen</th>
                    </tr>
                    <tr>
                        <td>Diverse Personalstammdaten inkl. Merhfacheinträge wie Kinder, Ein/Austritte, Ausbildungen, ..</td>
                        <td>Man kann dies für aktive oder inaktive Mitarbeitende machen</td>
                    </tr>
                    <tr>
                        <td>Lohn- oder Salärhistory</td>
                        <td>Lohnentwicklungen ist eine wertvolle Grösse und sollte im neuen System vorhanden sein</td>
                    </tr>
                    <tr>
                        <td>Dokumente</td>
                        <td>Es können diverse Dokumente der Mitarbeitenden in das Abacus Dossier geladen werden</td>
                    </tr>
                    <tr>
                        <td>Spezielle Felder (individuelle Felder)</td>
                        <td>Zusätzliche Felder</td>
                    </tr>
                </table>
                <br />
                <br />
                <br />
                <p>Aus folgenden HR Systemen sind Migrationen bereits mehrfach umgesetzt worden</p>
                <table>
                    <tr>
                        <th>Von HR System</th>
                        <th>Nach HR System</th>
                    </tr>
                    <tr>
                        <td>SAP HCM</td>
                        <td>Abacus G4</td>
                    </tr>
                    <tr>
                        <td>P&amp;I Loga</td>
                        <td>Abacus G4</td>
                    </tr>
                    <tr>
                        <td>PerSal</td>
                        <td>Abacus G4</td>
                    </tr>
                    <tr>
                        <td>Xpert.HRM (Soreco HR)</td>
                        <td>Abacus G4</td>
                    </tr>
                </table>
                <br />
                <p>Für weitere Quellen fragen Sie uns.</p>
            </div>


        </section>
    </Layout>
)


export default abacusHR
